<template>
  <div class="container">
    <div class="search-wrap">
      <el-image
        class="banner"
        :src="require('@/assets/images/partsRetrieval/parts-banner.png')"
      ></el-image>
      <div class="search">
        <el-input
          type="text"
          v-model="searchText"
          placeholder="请输入品牌名称/配件名称/配件型号进行搜索"
        />
        <div class="button" @click="handleDrop(searchText, 'keyword')">
          <i class="icon el-icon-search"></i>
        </div>
      </div>
    </div>
    <div class="brand-select">
      <div class="title">
        <el-image
          class="icon"
          :src="require('@/assets/images/partsRetrieval/parts-titleIcon.png')"
        ></el-image
        >工程机械品牌
      </div>
      <div class="list">
        <i class="el-icon-arrow-left" @click="handleArrow('-')"></i><i class="el-icon-arrow-right" @click="handleArrow('+')"></i>
        <div class="item-box" ref="brand-box">
          <div class="item" v-for="(item,index) in brandInfoPageList" :key="index" @click="handleDrop(item.brandName, 'partBrand')">
            <div
              class="icon"
            >
              <img :src="`${item.brandPicture ? item.brandPicture :  require('@/assets/images/partsRetrieval/parts-defaultImg.png')}`" alt="" srcset="">
              <div class="icon-text" v-if="!item.brandPicture">{{ item.brandName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="type-select">
      <div class="title">
        <el-image
          class="icon"
          :src="require('@/assets/images/partsRetrieval/parts-titleIcon.png')"
        ></el-image
        >工程机械种类
      </div>
      <div class="list">
        <div class="item" :class="{ 'disabled-item': !item.number }" v-for="item,index in typesOfConstructionMachineryList" :key="index" @click="handleDrop(item.name, 'deviceType', item.number)">{{ item.name }}（{{item.number}}）</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBrandInfoPageList, getKindInfoAndNumber } from "@/api/basicData"
export default {
  name: "partsHomeManagement",
  data() {
    return {
      searchText: "",
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      scrollNum: 0
    };
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      getKindInfoAndNumber({
        index: 1,
        size: 9999,
      }).then(res=>{
        this.typesOfConstructionMachineryList = res.data.typesOfConstructionMachineryList || [];
        
        this.typesOfConstructionMachineryList.unshift({
          name: "全部",
          number: res.data.allNumber
        })
        // this.brandInfoPageList = res.data.constructionMachineryBrandList || [];
      });
      getBrandInfoPageList({
        pageNum: 1,
        pageSize: 9999,
        current: 1,
        size: 9999
      }).then(res=>{
        // this.brandInfoPageList = res.data.records;
        let nameList = res.data.records.map(val=> val.brandName);
        this.brandInfoPageList = res.data.records.filter((item, index) => nameList.indexOf(item.brandName) === index);
      });
    },
    handleArrow(type) {
      type == '-' ? this.scrollNum -= this.$refs['brand-box'].clientWidth : this.scrollNum += this.$refs['brand-box'].clientWidth;
      let width = (Math.round(this.brandInfoPageList.length / 2) * 190) - this.$refs['brand-box'].clientWidth;
      this.scrollNum > width ? this.scrollNum = width : this.scrollNum < 0 ? this.scrollNum = 0: ''
      this.$refs['brand-box'].scrollTo(this.scrollNum, 0)
    },
    handleDrop(name, type, number) {
      if (type == 'deviceType' && !number) {
        return false;
      }
      this.$router.push({
        path: '/routerPartsRetrieval/partsRetrievalListPage?'+type + '=' + name
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
}
.search-wrap {
  position: relative;
  .banner {
    width: 100%;
  }
  .search {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -80%);
    display: flex;
    align-items: center;
    border-radius: 99px;
    padding-left: 10px;
    background-color: #fff;
    overflow: hidden;
    width: 550px;
    ::v-deep .el-input__inner {
      border: none;
      height: 40px;
      line-height: 40px;
    }
    .button {
      background-color: #4278c9;
      height: 40px;
      width: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        color: #fff;
        font-size: 18px;
      }
    }
  }
}
.brand-select {
  .item-box {
    margin: 0 80px;
    display: flex;
    height: 200px;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    scrollbar-height: none;
  }
  .item-box::-webkit-scrollbar {
    height: 0;
  }
  .list {
    position: relative;
    .el-icon-arrow-left,
    .el-icon-arrow-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      padding: 11px;
      border-radius: 30px;
      background-color: #f2f7fd;
      color: #4278c9;
      cursor: pointer;
    }
    .el-icon-arrow-left {
      left: 12px;
    }
    .el-icon-arrow-right {
      right: 12px;
    }
    .item {
      width: 170px;
      height: 84px;
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      &:nth-child(even) {
        margin-bottom: 0;
      }
      .icon {
        position: relative;
        width: 170px;
        height: 84px;
        padding: 14px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        &-text {
          width: 140px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 18px;
          text-align: center;
          color: #666666;
        }
        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        width: 170px;
        height: 84px;
          z-index: 0;
        }
      }
    }
  }
}
.brand-select,
.type-select {
  margin-bottom: 20px;
  padding: 10px 30px;
  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #333;
    font-weight: bold;
    margin-bottom: 20px;
    .icon {
      width: 6px;
      height: 24px;
      margin-right: 8px;
    }
  }
}
.type-select {
  font-size: 14px;
  .list {
    margin: 0 80px;
    display: flex;
    flex-wrap: wrap;
    .item {
      padding: 6px 12px;
      border: 1px solid #333;
      margin: 0 20px 20px 0;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        color: #4278c9;
        background-color: #eff6ff;
        border-color: #4278c9;
      }

    }
    .disabled-item {
      opacity: .5;
    }
  }
}
</style>
